import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/SchoolProgram/2024/Nobagday/1.jpg';
// import p2 from 'assests/Photos/SchoolProgram/2024/Nobagday/2.jpg';
// import p3 from 'assests/Photos/SchoolProgram/2024/Nobagday/3.jpg';
// import p4 from 'assests/Photos/SchoolProgram/2024/Nobagday/4.jpg';


import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from 'views/SchoolProgram2023/components';
import Sidebar2024 from '../SchoolProgram2023/components/Sidebar2024';
import Archive2022 from '../SchoolProgram2023/components/Archive2022';


const Teacherday2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });


    const [config, setConfig] = useState({ base_image_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
const p1 = `${config.base_image_url}/home/events-activities/school-programs/Teachersday/1.webp`;
const p2 = `${config.base_image_url}/home/events-activities/school-programs/Teachersday/2.webp`;
const p3 = `${config.base_image_url}/home/events-activities/school-programs/Teachersday/3.webp`;
const p4 = `${config.base_image_url}/home/events-activities/school-programs/Teachersday/4.webp`;
const p5 = `${config.base_image_url}/home/events-activities/school-programs/Teachersday/5.webp`;
const p6 = `${config.base_image_url}/home/events-activities/school-programs/Teachersday/6.webp`;
const p7 = `${config.base_image_url}/home/events-activities/school-programs/Teachersday/7.webp`;
const p8 = `${config.base_image_url}/home/events-activities/school-programs/Teachersday/8.webp`;
const p9 = `${config.base_image_url}/home/events-activities/school-programs/Teachersday/9.webp`;
const p10 = `${config.base_image_url}/home/events-activities/school-programs/Teachersday/10.webp`;
const p11 = `${config.base_image_url}/home/events-activities/school-programs/Teachersday/11.webp`;
const p12 = `${config.base_image_url}/home/events-activities/school-programs/Teachersday/12.webp`;
const p13 = `${config.base_image_url}/home/events-activities/school-programs/Teachersday/13.webp`;
const p14 = `${config.base_image_url}/home/events-activities/school-programs/Teachersday/14.webp`;
const p15 = `${config.base_image_url}/home/events-activities/school-programs/Teachersday/15.webp`;
const p16 = `${config.base_image_url}/home/events-activities/school-programs/Teachersday/16.webp`;
const p17 = `${config.base_image_url}/home/events-activities/school-programs/Teachersday/17.webp`;
const p18 = `${config.base_image_url}/home/events-activities/school-programs/Teachersday/18.webp`;


    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
          },
          {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
          },
          {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
          },
          {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
          },
          {
            src: p5,
            source: p5,
            rows: 1,
            cols: 1,
          },
          {
            src: p6,
            source: p6,
            rows: 1,
            cols: 1,
          },
          {
            src: p7,
            source: p7,
            rows: 1,
            cols: 1,
          },
          {
            src: p8,
            source: p8,
            rows: 1,
            cols: 1,
          },
          {
            src: p9,
            source: p9,
            rows: 1,
            cols: 1,
          },
          {
            src: p10,
            source: p10,
            rows: 1,
            cols: 1,
          },
          {
            src: p11,
            source: p11,
            rows: 1,
            cols: 1,
          },
          {
            src: p12,
            source: p12,
            rows: 1,
            cols: 1,
          },
          {
            src: p13,
            source: p13,
            rows: 1,
            cols: 1,
          },
          {
            src: p14,
            source: p14,
            rows: 1,
            cols: 1,
          },
          {
            src: p15,
            source: p15,
            rows: 1,
            cols: 1,
          },
          {
            src: p16,
            source: p16,
            rows: 1,
            cols: 1,
          },
          {
            src: p17,
            source: p17,
            rows: 1,
            cols: 1,
          },
          {
            src: p18,
            source: p18,
            rows: 1,
            cols: 1,
          }
    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                Teacher’s Day
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                 Class: Pre-Primary      Date: 10 September 2024
                                </Typography>
                                <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “With pure hearts and beaming smiles, our young learners gathered to celebrate the guiding stars in their lives—their cherished teachers.””

                                <br />
                            </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                At National Public School Yeshwanthpur, our Pre-Primary students marked Teacher’s Day with a series of lively performances, showcasing their affection and appreciation for their beloved teachers. The event was brimming with enthusiasm, creativity and heartfelt gratitude by the students for their educarers.
                                    <br></br>
                                    The celebration began with a spirited round of Antakshari, where our young ones filled the room with their warm, melodious voices. The students eagerly sang to their teachers, transforming the event into a joyful and musical experience that resonated with everyone present. The students took to the stage for a delightful array of Little Star dances. Dressed in colourful costumes, they gracefully performed to tunes that celebrated the spirit of learning and the special bond between teachers and students, followed by a simple yet profound mime conveying the message that students and teachers create an everlasting bond that continues for eternity. The celebration concluded with a power-packed finale dance, providing a perfect and uplifting end to a day filled with celebration and joy. Each performance was met with applause and smiles, as the children put on a captivating show for their teachers.
                                    <br></br>
                                    The Teachers' Day event proved to be both memorable and meaningful, highlighting the deep connection between students and their teachers. It was a beautiful tribute to the vital role teacher’s play in guiding and inspiring the students. 
                                    <br></br>
                                    As a school, we are proud to cultivate an environment where even our youngest students learn to express gratitude and respect through thoughtful and creative activities. "May the seeds of kindness, love and wisdom sown by the teachers today blossom into a world filled with compassion and understanding tomorrow."
                                    <br></br>

                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “ A VERY HAPPY TEACHERS' DAY TO ALL OUR BELOVED TEACHERS! ”
                                <br />
                                Educators: the unsung heroes who light up our future. Here’s to you!
                                <br />
                                Celebrating the incredible teachers who make every day a learning adventure!
                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                    <Box marginBottom={4}>
                            <Sidebar2024/>
                        </Box> 
                        <Box marginBottom={4}>
                            <Archive2022/>
                        </Box>

                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default Teacherday2024;